<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <v-nav-menu
      :navMenuItems = "navMenuItems"
      title="Travelify"
      parent=".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

    <!-- Navbar -->
    <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
      <the-navbar-horizontal
        :navbarType= "navbarType"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

      <div style="height: 62px" v-if="navbarType === 'static'"></div>

      <h-nav-menu
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
        :navMenuItems="navMenuItems" />
    </template>

    <template v-else>
      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
    </template>

      <tp-auto-logout-modal />
      <!-- <tp-session-timeout-dialog /> -->
      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />
              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view v-if="isUserDataLoaded" @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer v-if="showFooter" />
    </div>
  </div>
</template>


<script>
import BackToTop           from 'vue-backtotop'
import HNavMenu            from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuItems        from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import TpAutoLogoutModal from '@/layouts/components/auto-logout/TpAutoLogoutModal.vue'

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    TpAutoLogoutModal
  },
  data () {
    return {
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      isUserDataLoaded  : false
    }
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark (val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
    },
    '$store.state.ActiveCompanyId' (val, oldVal) {
      // This will also mutate state.ActiveApplicationId w/c also has a watcher
      // oldVal is 0 after being redirected from login or browser tab has been refreshed
      if (oldVal == 0) return
      
      this.$vs.loading();
      this.$store.dispatch('auth/switchCompany', { companyId: val })
      .then(response => {
        return this.$store.dispatch('updateUserInfoExplicitCompany', { companyId: val } );
      })
      .then(response => {
        const activeUser = this.$store.state.AppActiveUser;
        this.$store.commit('UPDATE_APPLICATION_LIST', activeUser.applications)
        this.$store.commit('UPDATE_COMPANY_LIST', activeUser.companies)
        const activeAppId = activeUser.applications[0].id
        const activeAppName = activeUser.applications[0].name
        const activeAppFeatures = activeUser.applications[0].features
        const appStatsUrl = activeUser.applications[0].appStatsUrl
        const appStatsUrlLink = activeUser.applications[0].appStatsUrlLink
        
        this.$store.commit('UPDATE_ACTIVE_APP', { 
          id: activeAppId,
          name: activeAppName,
          features: activeAppFeatures || [],
          appStatsUrl: appStatsUrl,
          appStatsUrlLink: appStatsUrlLink 
        })
      })
      .catch(error => console.log(error))
      .finally(()=> this.$vs.loading.close())
    },
    '$store.state.ActiveApplicationId' (val, oldVal) {
      this.updateNavMenu(this.$store.state.ActiveCompanyId, val)
      const skipRedirect = this.$store.state.SkipRedirectAfterAppSwitch
      if(this.$router.currentRoute.path !== '/' && !skipRedirect) {
        this.$router.push('/')
      }
      this.$store.commit('UPDATE_SKIP_REDIRECT_AFTER_APP_SWITCH', false)
    }
  },
  computed: {
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth },
    showFooter () { return this.$store.state.showFooter }
  },
  
  methods: {
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility (layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    updateNavMenu (activeCompanyId, activeAppId) {
      const companyMenu = this.$_.find(this.navMenuItems, (item) => {
        return item.i18n === 'Company'
      })
      companyMenu.header = this.$store.state.ActiveCompanyName || 'Company'
      companyMenu.slug = 'Company'
      

      companyMenu.items = [
        {
          url: `/companies/${activeCompanyId || 0}`,
          name: 'Dashboard',
          slug: 'companyDashboard',
          icon: 'BarChartIcon',
          i18n: 'Company Dashboard'
        },
        {
          url: `/companies/${activeCompanyId}/settings`,
          name: 'Settings',
          slug: 'settings',
          icon: 'SettingsIcon',
          i18n: '',
          eitherPermissions:  'company_settings_read,company_settings_edit'
        },
        {
          url: `/companies/${activeCompanyId}/roles`,
          name: 'Roles',
          slug: 'roles',
          icon: 'ShieldIcon',
          i18n: '',
          eitherPermissions:  'company_roles_read,company_roles_edit'
        },
        {
          url: `/companies/${activeCompanyId}/users`,
          name: 'Users',
          slug: 'users',
          icon: 'UsersIcon',
          i18n: '',
          eitherPermissions:  'company_users_read,company_users_edit'
        },
        {
          url: `/companies/${activeCompanyId}/apps`,
          name: 'Applications',
          slug: 'applications',
          icon: 'GlobeIcon',
          i18n: '',
          eitherPermissions: 'company_apps_read,company_apps_edit'
        }
      ]

      const applicationMenu = this.$_.find(this.navMenuItems, (item) => {
        return item.i18n === 'Applications'
      })
      
      applicationMenu.header = this.$store.state.ActiveApplicationName || 'Applications'
      applicationMenu.slug = 'App'

      applicationMenu.items = [
        {
          url: `/applications/${activeAppId}`,
          name: 'Dashboard',
          slug: 'appdashboard',
          icon: 'BarChart2Icon',
          i18n: 'Application Dashboard'
        },
        {
          url: `https://api.travelify.io/admin/siteeditor/${activeAppId}`,
          name: 'Edit Website',
          slug: 'external',
          target: '_blank',
          icon: 'MonitorIcon',
          i18n: 'Edit Website',
          eitherPermissions: 'apps_website_edit'
        },
        {
          url: `/applications/${ activeAppId }/ordermanager/myorders`,
          name: 'Order Manager',
          slug: 'ordermanager',
          icon: 'ShoppingCartIcon',
          i18n: 'OrderManager',
          eitherPermissions: 'apps_orders_read,apps_orders_process,apps_orders_edit'
        },
        {
          url: `/applications/${activeAppId}/customers`,
          name: 'CRM',
          slug: 'crm',
          icon: 'UsersIcon',
          i18n: 'CRM',
          eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add'
        },
        {
          url: `/applications/${activeAppId}/enquiryViewer`,
          name: 'Enquiry Viewer',
          slug: 'enqviewer',
          icon: 'SearchIcon',
          i18n: 'EnquiryViewer'
        },
        {
          url: `/applications/${activeAppId}/suppliers/mysuppliers`,
          name: 'My Suppliers',
          slug: 'suppliers',
          icon: 'FolderIcon',
          i18n: '',
          eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
        },
        {
          url: `/applications/${activeAppId}/suppliers`,
          name: 'Suppliers Directory',
          slug: 'supplierdir',
          icon: 'FolderPlusIcon',
          i18n: '',
          eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
        },

        {
          url: `/applications/${activeAppId}/widgets`,
          name: 'My Widgets',
          slug: 'mywidgets',
          icon: 'BoxIcon',
          i18n: 'Widgets',
          eitherPermissions: 'apps_widgets_read,apps_widgets_edit,apps_widgets_add'
        },

        {
          url: `/applications/${activeAppId}/widgetDirectory`,
          name: 'Widget Directory',
          slug: 'widgetdir',
          icon: 'CodesandboxIcon',
          i18n: 'Widgets',
          eitherPermissions: 'apps_widgets_read,apps_widgets_edit,apps_widgets_add'
        }
        ,

        {
          url: `/applications/${activeAppId}/pricingRules`,
          name: 'Pricing Rules',
          slug: 'pricingrules',
          icon: 'DollarSignIcon',
          i18n: 'Pricing Rules',
          eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
        },
        {
          url: `/applications/${activeAppId}/depositRules`,
          name: 'Deposit Rules',
          slug: 'depositrules',
          icon: 'SlidersIcon',
          i18n: 'Deposit Rules',
          eitherPermissions: 'apps_rules_read,apps_rules_edit'
        },
        {
          url: `/applications/${activeAppId}/voucherRules`,
          name: 'Promo Codes',
          slug: 'voucherrules',
          icon: 'TagIcon',
          i18n: 'Promo Codes',
          eitherPermissions: 'apps_rules_read,apps_rules_edit'
        },
        {
          url: `/applications/${activeAppId}/translations`,
          name: 'Translations',
          slug: 'translations',
          icon: 'TypeIcon',
          i18n: 'Translations',
          eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
        },
        {
          url: `/applications/${activeAppId}/currencyExchange`,
          name: 'Currency Exchange',
          slug: 'currencyExchange',
          icon: 'DollarSignIcon',
          i18n: 'Currency Exchange',
          eitherPermissions: 'app_currex_read,app_currex_edit',
          requiredAppFeature: 'currencyexchange'
        },
        {
          url: `/applications/${activeAppId}/tools`,
          name: 'More Tools',
          slug: 'moretools',
          icon: 'ToolIcon',
          i18n: 'MoreTools',
          eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
        },
      ]

      const navMenutItems = JSON.parse(JSON.stringify([...companyMenu.items, ...applicationMenu.items]))
      this.$store.commit('UPDATE_NAV_MENU_ITEMS', navMenutItems )
    }
  },
  created () {

    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)
    
    const activeCompanyId = parseInt(localStorage.getItem('active_company_id')) || this.$store.state.ActiveCompanyId
    const activeCompanyName = localStorage.getItem('active_company_name') || this.$store.state.ActiveCompanyName

    // At this point it is expected that state.ActiveCompanyId has already been set if redirected from login
    // However, if browser tab is refreshed state.ActiveCompanyId is 0 so we need to retrieve from local storage
    if (!this.$store.state.ActiveCompanyId) {
      // This will skip the watcher since prev value of CompanyId is 0
      this.$store.commit('UPDATE_ACTIVE_COMPANY', { id: activeCompanyId, name: activeCompanyName })
    }

    this.$store.dispatch('updateUserInfoExplicitCompany', { companyId: activeCompanyId})
      .then(response => {
        const activeUser = this.$store.state.AppActiveUser;
        this.$store.commit('UPDATE_APPLICATION_LIST', activeUser.applications)
        this.$store.commit('UPDATE_COMPANY_LIST', activeUser.companies)

        const activeAppId = parseInt(localStorage.getItem('active_application_id')) || activeUser.applications[0].id
        const activeAppName = localStorage.getItem('active_application_name') || activeUser.applications[0].name
        const activeAppFeatures = JSON.parse(localStorage.getItem('active_application_features')) || activeUser.applications[0].features || []

        let appStatsUrl = null
        let appStatsUrlLink = null

        if(localStorage.getItem('active_application_id')) {
          // active_application_app_stats_url and active_application_app_stats_url_link are nullable but local storage doesn't have null value
          appStatsUrl = localStorage.getItem('active_application_app_stats_url')
          appStatsUrlLink = localStorage.getItem('active_application_app_stats_url_link')
        } else {
          appStatsUrl = activeUser.applications[0].appStatsUrl
          appStatsUrlLink = activeUser.applications[0].appStatsUrlLink
        }
        
        this.$store.commit('UPDATE_SKIP_REDIRECT_AFTER_APP_SWITCH', true)
        // this will fire watcher 
        this.$store.commit('UPDATE_ACTIVE_APP', { 
          id: activeAppId,
          name: activeAppName,
          features: activeAppFeatures || [],
          appStatsUrl: appStatsUrl,
          appStatsUrlLink: appStatsUrlLink 
        })

        //IMPORTANT: Component loading is pending until this is set to true
        this.isUserDataLoaded = true
      })
  },
  mounted () {
    this.updateNavMenu(this.$store.state.ActiveCompanyId, this.$store.state.ActiveApplicationId)
  },
}

</script>

<style lang="scss">
  .v-nav-menu .vs-sidebar--item a span {
      font-size: 13px !important;
  }
</style>
